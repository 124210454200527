<template>
  <div>
    <!--  Page Header  -->
    <div class="page-header d-flex justify-content-between align-items-center">
      <div class="d-flex align-items-center">
        <router-link :to="{ name: 'resource_center' }">
          <button class="btn btn-control">
            <img alt="Return back" src="@/assets/icons/bold-icon previous.svg">
          </button>
        </router-link>
        <h3 class="page-header-title ms-2">Business Card</h3>
        <span class="subtitle">Generate Business Card</span>
      </div>
      <base-button title="Download" action="primary" @click-btn="saveData" :loading="ui.generating" />
    </div>

    <div class="page-content">
      <div v-if="ui.loading" class="text-center mt-5">
        <div class="spinner-border mt-5" role="status">
          <span class="visually-hidden">Loading...</span>
        </div>
      </div>
      <div v-else class="row">
        <div class="col-lg-8 generator-col border-right-light">
          <div v-if="activeTab('ClearMortgageCapital')" class="row">
            <div class="col-lg-6">
              <div class="page-count">Back Side</div>
              <pdf :src="src" :page="1" class="page-preview"/>
            </div>
            <div class="col-lg-6">
               <div class="page-count">Front Side</div>
              <pdf :src="src" :page="2" class="page-preview"/>
            </div>
          </div>
          <div v-if="activeTab('ClearRealty')" class="row">
            <div class="col-lg-6">
              <div class="page-count">Back Side</div>
              <pdf :src="src" :page="1" class="page-preview"/>
            </div>
            <div class="col-lg-6">
              <div class="page-count">Front Side</div>
              <pdf :src="src" :page="2" class="page-preview"/>
            </div>
          </div>
        </div>
        <div class="col-lg-4 generator-col">
          <div class="row">
            <div class="col-12">
              <div class="generator-col__title">
                Card Details
              </div>
            </div>
            <div class="col-12">
              <label for="fieldWorkflow">Select Card</label>
              <multiselect id="fieldWorkflow"
                           v-model="formData.activeTab"
                           :allow-empty="false"
                           :close-on-select="true"
                           :options="tabs"
                           :searchable="false"
                           :show-labels="false"
                           class="full-width"
                           @input="updatePreview"
                           label="label"
                           placeholder="Select Company"
                           track-by="value">
              </multiselect>
            </div>
            <div v-if="activeTab('ClearMortgageCapital')" class="col-12 mt-3">
              <label>Card type</label>
              <div class="mt-1">
                <div class="d-flex">
                  <span
                    :class="{ active: formData.isQrCard === false }"
                    class="check-btn"
                    @click="changePdfType(false)">Standard
                  </span>
                  <span
                    :class="{ active: formData.isQrCard === true }"
                    class="check-btn"
                    @click="changePdfType(true)">QR Code
                  </span>
                </div>
              </div>
            </div>
            <div class="col-md-6 mt-3">
              <div class="form-group">
                <label for="nameField">Name</label>
                <input id="nameField" @change="updatePreview" v-model="formData.name" class="form-control" minlength="3" type="text">
              </div>
            </div>
            <div class="col-md-6 mt-3">
              <div class="form-group">
                <label for="positionField">Position</label>
                <input id="positionField" @change="updatePreview" v-model="formData.position" class="form-control" minlength="3" type="text">
              </div>
            </div>
          </div>
          <div v-if="activeTab('ClearMortgageCapital')" class="col-5 mt-3">
            <div class="form-group full-width">
              <label for="nmlsField">NMLS</label>
              <input id="nmlsField" @change="updatePreview" v-model="formData.nmls" class="form-control" type="text">
            </div>
          </div>
          <div v-else class="col-5 mt-3">
            <div class="form-group full-width">
              <label for="dreField">DRE#</label>
              <input id="dreField" @change="updatePreview" v-model="formData.dre" class="form-control" type="text">
            </div>
          </div>
          <div class="col-6 mt-3">
            <div class="form-group full-width">
              <label for="phoneField">Phone Number</label>
              <input id="phoneField" @change="updatePreview" v-model="formData.phone" v-mask="'(###) ###-####'" class="form-control"
                     type="text">
            </div>
          </div>
          <div class="col-12 mt-3">
            <div class="form-group full-width">
              <label for="emailField">Email</label>
              <input id="emailField" @change="updatePreview" v-model="formData.email" class="form-control" type="email">
            </div>
          </div>
          <div class="col-12 mt-3">
            <div class="form-group full-width">
              <label for="addressField">Address</label>
              <input id="addressField" @change="updatePreview" v-model="formData.address" class="form-control" type="text">
            </div>
          </div>
          <div class="col-6 mt-2">
            <div class="form-group">
              <input id="stateField" @change="updatePreview" v-model="formData.stateName" class="form-control" type="text">
            </div>
          </div>
          <div v-if="formData.isQrCard && activeTab('ClearMortgageCapital')"
               class="col-12 mt-3">
            <label for="fieldURLType">Select QR URL Type</label>
            <multiselect id="fieldURLType"
                          v-model="formData.urlType"
                          :allow-empty="false"
                          :close-on-select="true"
                          :options="urlTypes"
                          :searchable="false"
                          :show-labels="false"
                          class="full-width selected"
                          label="label"
                          placeholder="Select Company"
                          track-by="value"
                          @input="changeURLType">
            </multiselect>
          </div>
          <div v-if="formData.isQrCard && activeTab('ClearMortgageCapital')"
               class="col-12 mt-3">
            <div class="form-group full-width">
              <label for="fieldCustomURL">QR URL</label>
              <input id="fieldCustomURL" @change="updatePreview" v-model="formData.customURL" class="form-control" required
                     type="url">
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Multiselect from 'vue-multiselect';
import pdf from 'vue-pdf'
import { getBackendUrl } from "@/utils/backendUrl";
import BaseButton from '../../components/BaseButton.vue';

export default {
  name: 'BusinessCardGenerator',
  components: { Multiselect, pdf, BaseButton },
  data() {
    return {
      formData: this.$store.getters.getBusinessCardData,
      src: null,
      ui: {
        loading: false,
        generating: false,
        loadingPreview: false
      },
      tabs: [
        {value: 'ClearRealty', label: 'Clear Realty, Inc.'},
        {value: 'ClearMortgageCapital', label: 'Clear Mortgage Capital, Inc.'}
      ],
      urlTypes: [
        {value: '', label: 'Apply URL'},
        {value: '', label: 'MLO Profile URL'},
        {value: '', label: 'Custom URL'}
      ]
    }
  },
  methods: {
    getData() {
      this.ui.loading = true

      this.$http.get(`/api/v1/business-card/get-qr-urls/${this.$store.getters.getUserId}`).then(res => {
        this.urlTypes = res.data
        this.$store.commit('saveBusinessCard', {
          name: this.formData.name ? this.formData.name : this.$store.getters.getFullName,
          position: this.formData.position ? this.formData.position : this.$store.getters.getPosition,
          nmls: this.formData.nmls ? this.formData.nmls : this.$store.getters.getNmls,
          phone: this.formData.phone ? this.formData.phone : this.$store.getters.getPhone,
          email: this.formData.email ? this.formData.email : this.$store.getters.getEmail,
          address: this.formData.address,
          stateName: this.formData.stateName,
          dre: this.formData.dre,
          activeTab: this.formData.activeTab ? this.formData.activeTab : this.tabs[1],
          isQrCard: this.formData.isQrCard,
          customURL: this.formData.customURL ? this.formData.customURL : res.data[1].value,
          urlType: this.formData.urlType ? this.formData.urlType : res.data[1]
        })

        this.formData = this.$store.getters.getBusinessCardData
      }).finally(() => this.ui.loading = false)
    },
    activeTab(val) {
      return val === this.formData.activeTab.value
    },
    saveData() {
      this.ui.generating = true
      this.$store.commit('saveBusinessCard', this.formData)

      if (this.activeTab('ClearMortgageCapital')) {
        this.$http.get(`/api/v1/business-card/cmc`, {
          params: this.formData,
          responseType: 'arraybuffer'
        }).then(res => {
          this.generateFile(res);
        }, err => {
          console.log(err)
          this.pushAlert('error', 'Failed Generate')
          this.ui.generating = false
        })
      } else if (this.activeTab('ClearRealty')) {
        this.$http.get(`/api/v1/business-card/realty`, {
          params: this.formData,
          responseType: 'arraybuffer'
        }).then(res => {
          this.generateFile(res);
        }, err => {
          console.log(err)
          this.pushAlert('error', 'Failed Generate')
          this.ui.generating = false
        })
      }

    },
    generateFile(res) {
      let fileURL = window.URL.createObjectURL(new Blob([res.data], {type: 'application/zip'})),
        fileLink = document.createElement('a');

      fileLink.href = fileURL;

      if (this.activeTab('ClearMortgageCapital')) {
        fileLink.setAttribute('download', 'cmc_business_card.zip');
      } else if (this.activeTab('ClearRealty')) {
        fileLink.setAttribute('download', 'clear_realty_business_card.zip');
      }

      document.body.appendChild(fileLink);
      fileLink.click();
      this.ui.generating = false
      fileLink.remove();
    },
    changeURLType(value) {
      this.formData.customURL = value.value
      this.updatePreview()
    },
    updatePreview() {
      this.ui.loadingPreview = true
      this.$store.commit('saveBusinessCard', this.formData)
      if (this.activeTab('ClearMortgageCapital')) {
        this.src = pdf.createLoadingTask(this.generateCmcBaseUrl())
        this.src.promise.then(() => {
          this.ui.loadingPreview = false
        })
      } else if (this.activeTab('ClearRealty')) {
        this.src = pdf.createLoadingTask(this.generateClearRealtyBaseUrl())
        this.src.promise.then(() => {
          this.ui.loadingPreview = false
        })
      }
    },
    changePdfType(val) {
      if (val === true) {
        this.formData.isQrCard = true
      } else if (val === false) {
        this.formData.isQrCard = false
      }
      this.updatePreview()
    },
    generateCmcBaseUrl() {
      let formParams = JSON.parse(JSON.stringify(this.formData));
      formParams.getPdf = 1;
      formParams.activeTab = formParams.activeTab.value;
      formParams.id = this.$store.getters.getUserId;
      delete formParams.urlType;
      return getBackendUrl() + '/api/v1/business-card/cmc?' + this.convertObjectToQueryParams(formParams);
    },
    generateClearRealtyBaseUrl() {
      let formParams = JSON.parse(JSON.stringify(this.formData));
      formParams.getPdf = 1;
      formParams.activeTab = formParams.activeTab.value;
      delete formParams.urlType;
      return getBackendUrl() + '/api/v1/business-card/realty?' + this.convertObjectToQueryParams(formParams);
    }
  },
  beforeMount() {
    this.getData()
  },
  mounted() {
    this.ui.loadingPreview = true
    if (this.activeTab('ClearMortgageCapital')) {
      this.src = pdf.createLoadingTask(this.generateCmcBaseUrl())
      this.src.promise.then(() => {
        this.ui.loadingPreview = false
      })
    } else if (this.activeTab('ClearRealty')) {
      this.src = pdf.createLoadingTask(this.generateClearRealtyBaseUrl())
      this.src.promise.then(() => {
        this.ui.loadingPreview = false
      })
    }
  },
}
</script>

<style lang="scss" scoped>
.page-preview {
  display: inline-block;
  width: 100%;
  border: #0a0a0a 1px solid;
  margin-bottom: 30px;
}

.page-count {
  width: fit-content;
  background-color: #989898;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 2px;
  color: #ffffff;
}

.page-header {
  button {
    &.btn-primary {
      min-width: 140px;
    }
  }
}

.page-content {

  .generator-col {
    padding: 24px 30px;
    min-height: 100vh;

    &::v-deep .multiselect {
      width: auto;

      &.multiselect--active {
        .multiselect__tags {
          transition: all .3s;
          border: 1px solid rgba(38, 111, 229, 0.5);
          box-shadow: 0 0 0 4px rgba(38, 111, 229, 0.1);
        }
      }

      &__tags {
        min-height: 36px;
        max-height: 36px;
        padding: 8px 36px 0 7px;
        transition: all .3s;
        border: 1px solid #B4B4B4;
        border-radius: 4px;
        background-color: #FFFFFF;
        width: 100%;

        &:hover {
          border: 1px solid rgba(38, 111, 229, 0.2);
          box-shadow: 0 0 0 4px rgba(38, 111, 229, 0.1);
        }
      }

      &__select {
        &:before {
          top: 10%;
          position: relative;
          right: 0;
          color: #999;
          border: solid rgba(0, 0, 0, 0.46);
          margin-top: 4px;
          border-width: 0px 1px 1px 0;
          display: inline-block;
          padding: 3px;
          content: "";
          transform: rotate(44deg);
        }
      }

      &__single {
        color: #000000;
        font-size: 14px;
        letter-spacing: 0;
        line-height: 18px;
        margin-bottom: 0;
        @media screen and (max-width: 568px) {
          font-size: 9px;
        }
      }

      &__option {
        padding: 10px 12px;
        min-height: 36px;
        transition: all .3s;
        line-height: 20px;
        font-size: 14px;
        color: #000;
        @media screen and (max-width: 568px) {
          font-size: 9px;
        }

        &--highlight {
          background: #f3f3f3;
          line-height: 20px;
          font-size: 14px;
          color: #000;
          @media screen and (max-width: 568px) {
            font-size: 9px;
          }
        }
      }

      &__placeholder {
        padding: 0;
      }
    }

    &__title {
      color: #000000;
      font-size: 18px;
      font-weight: 600;
      letter-spacing: 0;
      line-height: 21px;
      margin-bottom: 24px;
    }

    label {
      color: rgba(0, 0, 0, .7);
      font-size: 12px;
      letter-spacing: 0;
      line-height: 12px;
    }
  }

  .form-group {

    input {
      &.form-control {
        max-height: 36px;
        height: 36px;
        min-height: 36px;
        border: 1px solid #B4B4B4;
        border-radius: 4px;
        background-color: #FFFFFF;
        color: #000000;
        font-size: 14px;
        letter-spacing: 0;
        line-height: 18px;
      }
    }
  }
}

.check-btn {
  padding: 12px 15px !important;
}
</style>
